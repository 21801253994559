/* eslint-disable require-atomic-updates */
import { nextTick } from 'vue'
import { updatePageData, onSubscribeListExpose } from './useAnalysis'
import { bffHypernymGoods } from '@/public/src/pages/product_list_v2/js/bffHypernymGoods.js'
import { EventBus } from 'public/src/pages/common/event-bus.js'
import { FIRST_LOAD } from './useConstant'

// 上位词查询商品Info
const hypernymProductInfo = {
  list: [],
  page: 1,
  limit: 20,
  sum: null, // null:默认 number: 上位词请求回来后的总数
  keywords: '',
  loading: '',
  noMoreData: false,
  request_ext: {},
  emptyLang: '',
  noEmptyLang: '',
  useBffApi: false,
  searchResultInterfaceCode: ''
}

const initHypernymProductInfo = (vm) => {
  vm.hypernymProductInfo = {
    list: [],
    page: 1,
    limit: 20,
    sum: null, // null:默认 number: 上位词请求回来后的总数
    loading: '',
    noMoreData: false,
    searchResultInterfaceCode: ''
  }
}

// 处理上位词
const handleHypernymFetch = (vm, { newData }) => {
  initHypernymProductInfo(vm)
  const { requestType } = newData?.cat_info || {}
  if (requestType === FIRST_LOAD || !vm.hypernymProductInfo.keywords) {
    vm.hypernymProductInfo.keywords = newData.searchKeywords?.origin_words
  }
  // v.趋势搜索结果页屏蔽该功能
  if (
    vm.hypernymProductInfo.sum === 0 || 
    vm.catInfo.type !== 'search' || 
    ['store', 'brand', 'pageTrend'].includes(vm.catInfo.search_type)
  ) {
    // 这里表示firstload的时候用searchword请求结果为0，因此不再重复请求
    // 非搜索结果页面不去获取上位词商品
    vm.hypernymProductInfo['noMoreData'] = true
    return
  }
  const { page, limit } = vm.Request.query
  const lastPaged = parseInt(page) >= Math.ceil(newData.sum / limit)
  if (!lastPaged || !vm.hypernymProductInfo.keywords || newData.sum > 200) {
    vm.hypernymProductInfo['noMoreData'] = true
    return
  }
  fetchHypernyData(vm)
}

// 请求上位词数据
const fetchHypernyData = async (vm, { requestType } = {}) => {
  if (requestType === 'nextpage') {
    vm.hypernymProductInfo.page = parseInt(vm.hypernymProductInfo.page) + 1
  }

  const { keywords, list } = vm.hypernymProductInfo
  let hPKeywords = keywords
  if (!hPKeywords) return
  vm.hypernymProductInfo.loading = 'nextpage'
  try {
    let goods = []
    let searchResultInterfaceCode = ''
    let request_ext = null
    let hypernymProduct = null
    const { hypernymProduct: hypernymProductData, request_ext: reqExt, buriedPoint, products, hyCardConfig } = await bffHypernymGoods({
      toRoute: vm.$route,
      query: vm.hypernymProductInfo,
      catInfo: vm.catInfo,
      language: vm.language,
      goods: vm.goods,
      lang: vm.lang,
      sheinClubInfo: vm.sheinClubInfo
    })
    if (!vm.goods.length && !vm.ComponentState.ProductList.config.showTitle) {
      vm.setCardConfig({
        cardConfig: {
          ...vm.ComponentState.ProductList.config, 
          ...hyCardConfig 
        }
      })
    }
    goods = products || []
    searchResultInterfaceCode = buriedPoint || 1
    request_ext = reqExt
    hypernymProduct = Object.assign(hypernymProductData, { useBffApi: true })

    vm.updateSpecificAttr({
      key: 'request_ext',
      value: {
        ...reqExt,
        ...vm.request_ext
      }
    })
    // 更新埋点数据
    updatePageData(vm, { 
      search_rec_request_status: searchResultInterfaceCode || '4_1001'
    })
    hypernymProduct.sum = +hypernymProduct.sum
    hypernymProduct.searchResultInterfaceCode = searchResultInterfaceCode || '4_1001'
    Object.assign(vm.hypernymProductInfo, hypernymProduct, { list: [...list, ...goods] })
    if(hypernymProduct.page == 1) {
      updatePageData(vm, { 
        query_infor: getRequestExtForAna(vm.request_ext, request_ext),
        rec_result_count: getHypernymProductSumForAna(vm)
      })
    }

    await nextTick()

    if (requestType === 'nextpage') {
      initHypernymListExpose(vm)
    }

  } finally {
    vm.hypernymProductInfo.loading = ''
    vm.showPageRenderDone = true
  }
}

const initHypernymListExpose = (vm) => {
  const subscribeFn = () => {
    vm.$refs.HypernymProductWrap?.setAnalysisData()
    const { config } = vm.ComponentState.ProductList || {}
    onSubscribeListExpose(vm, config.itemDAEventExposeId)
  }
  if (vm.ListAnalytics) {
    subscribeFn()
  } else {
    EventBus.once('ListAnalyticsReady', () => {
      subscribeFn()
    })
  }
}

const getRequestExtForAna = (mainProductsRequestExt, hypernymRequestExt) => {
  const requestExt = Object.assign({}, hypernymRequestExt || {}, mainProductsRequestExt || {})
  return Object.entries(requestExt)
    .map(([key, value]) => `${key}_${value ?? ''}`)
    .join('|')
}

const getHypernymProductSumForAna = (vm) => {
  return vm.hypernymProductInfo.sum || '-'
}

export {
  hypernymProductInfo,
  initHypernymProductInfo,
  handleHypernymFetch,
  fetchHypernyData,
  initHypernymListExpose
}
